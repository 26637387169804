import { useStaticQuery, graphql } from 'gatsby'
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author
            email
            twitter
            logo
            gsdb {
              docUrl
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
